<template>
  <div class="home">
    <SliderHome :slider="slider" v-if="desktop === true" />
    <SliderHomeMobile :slider="slider" v-if="mobile === true" />

    <BatchSolutions :data="slider" />
    <Results
      :results="results"
      :company_icons="company_icons"
      :icon="icon"
      :badges="badges"
    />
    <Sliders
      :title="titleOurCustomers"
      :data="dataOurCustomers"
      :active="dataOurCustomersActive"
    />
    <Sliders
      :title="titleOurPartners"
      :data="dataOurPartners"
      :active="dataOurPartnersActive"
    />
    <News :news="news" />
    <FormCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent } from "vue";

const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
const SliderHome = defineAsyncComponent(() =>
  import("@/components/SliderHome.vue")
);
const SliderHomeMobile = defineAsyncComponent(() =>
  import("@/components/SliderHomeMobile.vue")
);
const BatchSolutions = defineAsyncComponent(() =>
  import("@/components/BatchSolutions.vue")
);
const Results = defineAsyncComponent(() => import("@/components/Results.vue"));
const News = defineAsyncComponent(() => import("@/components/News.vue"));
const Sliders = defineAsyncComponent(() => import("@/components/Sliders.vue"));
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  name: "Home",
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("Home Page"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  props: {
    url: {
      type: String,
      employee: Object,
      default: process.env.VUE_APP_API,
    },
  },
  data() {
    return {
      title_page: "Home",
      titleOurCustomers: "",
      titleOurPartners: "",
      dataOurCustomers: [],
      dataOurPartners: [],
      dataOurCustomersActive: [],
      dataOurPartnersActive: [],
      slider: [],
      news: [],
      results: [],
      company_icons: [],
      badges: [],
      icon: [],
      locale: this.$i18n.locale,
      window: {
        width: 0,
      },
      desktop: true,
      mobile: false,
    };
  },
  components: {
    FormCommon,
    SliderHome,
    SliderHomeMobile,
    BatchSolutions,
    Results,
    News,
    Sliders,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    async getSliders() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/all-sliders",
          config
        );
        this.slider = response.data.home_slider.data.services.data;
        this.dataOurCustomers =
          response.data.our_customer_slider.data.slides.attachment;
        this.titleOurCustomers = response.data.our_customer_slider.data.name;
        this.dataOurCustomersActive =
          response.data.our_customer_slider.data.slides_active;
        this.dataOurPartnersActive =
          response.data.our_partner_slider.data.slides_active;
        this.dataOurPartners =
          response.data.our_partner_slider.data.slides.attachment;
        this.titleOurPartners = response.data.our_partner_slider.data.name;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getNews() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/last-posts",
          config
        );
        this.news = response.data.data;
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getResults() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/results",
          config
        );
        this.results = response.data.data.data;
        this.company_icons = response.data.data.attachment;
        this.icon = response.data.icon;
        this.badges = [
          response.data.icon_0,
          response.data.icon_1,
          response.data.icon_2,
        ];
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //что-то делаем после завершения
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 650) {
        (this.desktop = true), (this.mobile = false);
      } else if (this.window.width < 650) {
        (this.desktop = false), (this.mobile = true);
      }
    },
  },
  mounted() {
    if (this.$route.matched[0].name === "Home") {
      document.body.classList.add("index");
    }
    this.getSliders();
    this.getNews();
    this.getResults();
  },
});
</script>
